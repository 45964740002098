<template>
  <!--
    The view for the Template Manager
  -->
  <div class="installationDetail_Template">
    <template v-if="skilineSpots && skilineSpots.length > 0">
      <div
        id="nav-tab"
        class="nav nav-tabs"
        role="tablist"
      >
        <a
          v-for="(key, index) in skilineSpots"
          :id="`spoT${ index }-tab`"
          :key="`Template-tab-listItem-${ index }`"
          :class="['nav-item nav-link', {'active': index == 0}]"
          data-toggle="tab"
          :href="`#spoT${ index }`"
          role="tab"
          :aria-controls="`spoT${ index }`"
          :aria-selected="index === 0"
        >{{ getValue(key, false) }}</a>
      </div>
      <div
        id="nav-tabContent"
        class="tab-content"
      >
        <div
          v-for="(vals, index) in skilineSpots"
          :id="`spoT${ index }`"
          :key="`Template-session-listItem-${ index }`"
          :class="['tab-pane fade show', {'active': index == 0}]"
          role="tabpanel"
          :aria-labelledby="`spoT${ index }-tab`"
        >
          <iframe
            class="iframe"
            :src="`https://admin.skiline.cc/php/alturos/content/admin/id/tool/photoPointTemplates.php?installationId=${ getValue(vals, true) }`"
          />
        </div>
      </div>
    </template>

    <p v-else-if="skilineSpots.length == 0">
      No data available.
    </p>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailTemplate",
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
   data () {
    return {
      skilineSpots: [],
    }
  },
  metaInfo () {
    return {
      title: 'Template Manager'
    }
  },
  created () {
    this.getSpots();
  },
  methods: {
    getSpots () {
      this.axios.get(`/Installation/GetSkilineCameraNamesOrSpotIds?installationId=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.skilineSpots = response.data;
      })
    },
    getValue (spot, isId) {
      if(spot.id && spot.externalId) {
        return isId ? spot.id : spot.externalId;
      }
      else 
      {
        return spot.name;
      }
    }
  }
}
</script>
<style scoped>
.iframe {
  width: 100%;
  height: 100vh;
}
</style>